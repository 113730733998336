import React from "react"

import SEO from "../components/Base/SEO"
import Home from "../components/Home/Home"
import Layout from "../components/Base/Layout"

export default function home() {
  return (
    <Layout>
      <SEO title={"sheer analytics"} />
      <Home />
    </Layout>
  )
}
